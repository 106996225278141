<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-button @click="pdfExport()" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.print') }}
        </b-button>
        <b-col sm="12">
          <iq-card style="padding: 20px;">
            <template>
              <b-row>
                <b-col sm="8">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 20%;">{{ $t('pump_install.application_id') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ $n(pumpOperatorApp.application_id, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('globalTrans.date') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ pumpOperatorApp.created_at | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('pump_install.organization') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ ($i18n.locale == 'bn') ? pumpOperatorApp.org_name_bn : pumpOperatorApp.org_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('globalTrans.address') }}</th>
                      <td style="width: 5%;">:</td>
                      <td style="width: 75%;border-bottom: 1px dashed #999;">{{ getAddress(pumpOperatorApp) }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="4">
                  <img :src="baseUrl + 'download-attachment?file=uploads/pump-operator-application/applicant-photo/original/' + pumpOperatorApp.applicant_photo" class="img-fluid" style="border: 1px solid #999;float: right;width: 150px;height: 150px;"/>
                </b-col>
                <b-col sm="12" class="my-2">
                  <p class="mb-1">{{ $t('pump_install.dear_sir') }},</p>
                  <p class="mb-1">{{ $t('pump_install.i_am_writing_for_pump_operator') }} {{ $t('externalUserIrrigation.pump_id') }} ({{ pumpOperatorApp.pump_info_pump_id }}), {{ $t('externalUserIrrigation.address') }}: {{ getPumpAddress(pumpOperatorApp) }} "</p>
                  <p>{{ $t('pump_install.i_would_appreciate') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.sincerely') }}</th>
                      <td style="width: 2%;"></td>
                      <td style="width: 85%;"></td>
                    </tr>
                    <tr>
                      <th style="width: 13%;">{{ $t('globalTrans.name') }}</th>
                      <td style="width: 2%;">:</td>
                      <td style="width: 85%;">{{ ($i18n.locale == 'bn') ? pumpOperatorApp.name_bn : pumpOperatorApp.name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 13%;">{{ $t('pump_install.mobile') }}</th>
                      <td style="width: 2%;">:</td>
                      <td style="width: 85%;">{{ $n(0) + $n(pumpOperatorApp.far_mobile_no, { useGrouping: false }) }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="12">
                  <p class="text-center mt-1 mb-4">[{{ $t('pump_install.this_is_computer_generated') }}]</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.pump_operator_new_application') + ' ' + $t('externalUserIrrigation.details') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <table class="table table-sm table-borderless">
                    <tr>
                      <th style="width: 20%;">{{ $t('org_pro.organization') }} :</th>
                      <td>{{ ($i18n.locale == 'bn') ? pumpOperatorApp.org_name_bn : pumpOperatorApp.org_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%;">{{ $t('pump_install.pump_id') }} :</th>
                      <td>
                        <span v-html="pumpOperatorApp.pump_info_pump_id"></span>
                      </td>
                    </tr>
                  </table>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th>{{ $t('user.namel') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.name_bn : pumpOperatorApp.name }}</b-td>
                      <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                      <b-td>{{ pumpOperatorApp.date_of_birth | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.father_name_bn : pumpOperatorApp.father_name }}</b-td>
                      <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.mother_name_bn : pumpOperatorApp.mother_name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('org_pro_division.division') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.divion_name_bn : pumpOperatorApp.division_name }}</b-td>
                      <b-th>{{ $t('org_pro_district.district') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.district_name_bn : pumpOperatorApp.district_name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.upazilla_name_bn : pumpOperatorApp.upazilla_name }}</b-td>
                      <b-th>{{ $t('org_pro_union.union') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.union_name_bn : pumpOperatorApp.union_name }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('externalUserIrrigation.village') }}</b-th>
                      <b-td>{{ ($i18n == 'bn') ? pumpOperatorApp.far_village_bn : pumpOperatorApp.far_village }}</b-td>
                      <b-th>{{ $t('externalUserIrrigation.educational_qualification') }}</b-th>
                      <b-td>{{ pumpOperatorApp.qualification }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('user.phone_no') }}</b-th>
                      <b-td>{{ pumpOperatorApp.far_mobile_no }}</b-td>
                      <b-th>{{ $t('externalUserIrrigation.nid') }}</b-th>
                      <b-td colspan="3">{{ $t(pumpOperatorApp.nid) }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="mb-3" v-if="attachments.length > 0">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.attachment') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th>{{ $t('pump_install.document_title') }}</b-th>
                      <b-th>{{ $t('pump_install.attachment') }}</b-th>
                    </b-tr>
                    <b-tr v-for="(attachment, index) in attachments" :key="index">
                      <b-td>{{ ($i18n == 'bn') ? attachment.document_title_bn : attachment.document_title }}</b-td>
                      <b-td>
                        <a v-if="attachment.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/pump-operator-document/original/' + attachment.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="mb-3" v-if="approvals.length > 0">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.send') + ' ' + $t('pump_install.notel') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th>{{ $t('user.namel') }}</b-th>
                      <b-th>{{ $t('pump_install.send_for') }}</b-th>
                      <b-th>{{ $t('pump_install.notel') }}</b-th>
                    </b-tr>
                    <b-tr v-for="(approval, index) in approvals" :key="index">
                      <b-td>{{ getUserName(approval.sender_id) }}</b-td>
                      <b-td>{{ (approval.for === 1) ? $t('pump_install.check') : $t('pump_install.survey') }}</b-td>
                      <b-td>{{ ($i18n == 'bn') ? approval.note_bn : approval.note }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
              <b-row class="mb-3" v-if="surveys.length > 0">
                <b-col sm="12">
                  <blockquote class="blockquote" style="font-size: 1.1rem;border-left: 5px solid #228b22;padding-left: 5px;font-weight: 600;">
                    <p class="mb-0" style="line-height: 1.5;">{{ $t('pump_install.survey') + ' ' + $t('pump_install.notel') }}</p>
                  </blockquote>
                </b-col>
                <b-col sm="12">
                  <b-table-simple striped bordered small>
                    <b-tr>
                      <b-th>{{ $t('user.namel') }}</b-th>
                      <b-th>{{ $t('pump_install.survey_date') }}</b-th>
                      <b-th>{{ $t('pump_install.notel') }}</b-th>
                    </b-tr>
                    <b-tr v-for="(survey, index) in surveys" :key="index">
                      <b-td>{{ getUserName(survey.user_id) }}</b-td>
                      <b-td>{{ survey.survey_date }}</b-td>
                      <b-td>{{ ($i18n == 'bn') ? survey.note_bn : survey.note }}</b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col lg="12" sm="12">
          <b-overlay :show="loader">
            <div class="text-dark text-center">
              Loading...
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptApplicationDetailsList } from '../api/routes'
import ExportPdf from './pump_operator_application_details_pdf'

export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    this.loadData()
  },
  data () {
    return {
      loader: true,
      pumpOperatorApp: '',
      attachments: [],
      approvals: [],
      surveys: [],
      slOffset: 1,
      baseUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    applicationData () {
      return Object.assign({}, this.pumpOperatorApp)
    }
  },
  methods: {
    loadData () {
      RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationDetailsList + '/' + this.$props.id).then(response => {
        if (response.success) {
          this.pumpOperatorApp = this.formatList(response.data)
          this.attachments = response.attachments
          this.approvals = response.approvals
          this.surveys = response.surveys
          this.users = response.users.data
        }
        this.loader = false
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    formatList (data) {
      const orgList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList

      const orgPro = orgList.find(org => org.value === data.org_id)
      const division = divisionList.find(division => division.value === data.far_division_id)
      const district = districtList.find(district => district.value === data.far_district_id)
      const upazilla = upazilaList.find(upazilla => upazilla.value === data.far_upazilla_id)
      const union = unionList.find(union => union.value === data.far_union_id)
      return Object.assign({}, data,
        { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
        { division_name: division.text_en, division_name_bn: division.text_bn },
        { district_name: district.text_en, district_name_bn: district.text_bn },
        { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn },
        { union_name: union.text_en, union_name_bn: union.text_bn }
      )
    },
    getUserName (userId) {
      const user = this.users.find(usr => usr.value === userId)
      if (this.$i18n.locale === 'bn') {
        return user !== undefined ? user.text_bn : ''
      } else {
        return user !== undefined ? user.text_en : ''
      }
    },
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    pdfExport () {
      const reportTitle = this.$t('pump_install.pump_operator_new_application_details')
      ExportPdf.exportPdfDetails(reportTitle, this,
        this.applicationData
      )
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
