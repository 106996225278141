<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator_report') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    >
                        <template v-slot:label>
                            {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="organizationtList"
                        id="org_id"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <span v-show="orgErrorMsg" class="text-danger">{{ 'Organization field is required' }}</span>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('globalTrans.status')"
                        label-for="status"
                        >
                            <b-form-select
                            plain
                            v-model="search.status"
                            :options="getApplicationStatusList"
                            id="status"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_division.division')"
                    label-for="far_division_id"
                    >
                        <b-form-select
                        plain
                        v-model="search.far_division_id"
                        :options="divisionList"
                        id="far_division_id"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_district.district')"
                        label-for="far_district_id"
                        >
                            <b-form-select
                            plain
                            v-model="search.far_district_id"
                            :options="districtList"
                            id="far_district_id"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_upazilla.upazilla')"
                        label-for="far_upazilla_id"
                        >
                            <b-form-select
                            plain
                            v-model="search.far_upazilla_id"
                            :options="upazilaList"
                            id="far_upazilla_id"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t($t('org_pro_union.union'))"
                        label-for="far_union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.far_union_id"
                        :options="unionList"
                        id="far_union_id"
                        >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.from_date')"
                    label-for="from_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.to_date')"
                    label-for="to_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator_report') }}</h4>
          </template>
            <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                    <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                </b-button>
                <export-excel
                    class="btn btn_add_new"
                    :data="dataCustomize"
                    :fields= "json_fields"
                    :title="$t('irriPumpInformation.pump_operator_report')"
                    worksheet="Pump Operator Report Sheet"
                    name="filename.xls">
                    <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="irriSchemeServiceBaseUrl"
                                          :key="reloadListHeading"
                                          uri="report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('irriPumpInformation.pump_operator_report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="pumpReportList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:cell(name_bn)="data">
                      {{ data.item.name_bn }}
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      <span>{{ data.item.far_village_bn + ', ' + data.item.union_name_bn + ', ' + data.item.upazilla_name_bn + ', ' + data.item.district_name_bn + ', ' + data.item.division_name_bn }}</span>
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span>{{ data.item.far_village + ', ' + data.item.union_name + ', ' + data.item.upazilla_name + ', ' + data.item.district_name + ', ' + data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(view_application)="data">
                        <b-button class="btn-outline-success" v-b-modal.modal-7 variant=" iq-bg-success mr-1" size="sm" title="View Application"  @click="setRowId(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
        <PumpOptApplicationDetails :id="testId"/>
        </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpInstallPumpOperatorReport } from '../../api/routes'
import PumpOptApplicationDetails from '../PumpOptApplicationDetails'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from './export-pdf_details'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { mapGetters } from 'vuex'
import custom from '../../../../../store/custom'

Vue.use(excel)
const excelColumn = {
  Organization: 'org',
  'Operator ID': 'optId',
  'Operator Name': 'optName',
  'Pump Address': 'division',
  'Payment Status': 'pStatus',
  'Scheme License No': 'sLicenseNo',
  'Application Status': 'aStatus',
  Notes: 'notes'
}
const excelColumnBn = {
  সংস্থা: 'org',
  'অপারেটর আইডি': 'optId',
  'অপারেটরের নাম': 'optName',
  'পাম্পের ঠিকানা': 'division',
  'লেনদেনের অবস্থা': 'pStatus',
  'স্কিম লাইসেন্স নং': 'sLicenseNo',
  'আবেদনপত্রের অবস্থা': 'aStatus',
  মন্তব্য: 'notes'
}
export default {
    name: 'UiDataTable',
    components: {
        PumpOptApplicationDetails,
        ListReportHead
    },
    data () {
        return {
            reloadListHeading: 1,
            noDataFound: false,
            orgErrorMsg: false,
            division_text: '',
            district_text: '',
            upazila_text: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                slOffset: 1
            },
            showData: false,
            showHeading: false,
            search: {
                org_id: 0,
                status: 0,
                far_division_id: 0,
                far_district_id: 0,
                far_upazilla_id: 0,
                far_union_id: 0,
                from_date: '',
                to_date: ''
            },
            item: 0,
            organization: {},
            pumpReportList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            rows: [],
            irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
            reportHead: null,
            applicationStatusList: custom.pumpOptAppStatus,
            testId: 0
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const pumpReportList = this.pumpReportList
            pumpReportList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    item.org = item.org_name_bn
                    item.optId = item.farmer_id
                    item.optName = item.name_bn
                    item.division = item.far_village_bn + ', ' + item.union_name_bn + ', ' + item.upazilla_name_bn + ',' + item.district_name_bn + ',' + item.division_name_bn
                    item.pStatus = item.payment_status
                    item.sLicenseNo = ''
                    item.aStatus = item.status_bn
                    item.notes = ''
                } else {
                    item.org = item.org_name
                    item.optId = item.farmer_id
                    item.optName = item.name
                    item.division = item.far_village + ', ' + item.union_name + ', ' + item.upazilla_name + ', ' + item.district_name + ', ' + item.division_name
                    item.pStatus = item.payment_status
                    item.sLicenseNo = ''
                    item.aStatus = item.status_en
                    item.notes = ''
                }
                return Object.assign({}, item)
            })
            return pumpReportList
        },
        mapTitle () {
        return this.$t('irriPumpInformation.mapTitle')
        },
        organizationtList: function () {
            const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return listObject
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        getApplicationStatusList () {
            return this.applicationStatusList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        listReload () {
            return this.$store.state.commonObj.listReload
        },
        loadingState () {
            if (this.listReload) {
                return true
            } else if (this.loading) {
                return true
            } else {
                return false
            }
        },
        currentLocale () {
            return this.$i18n.locale
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('pump_install.operator_id'), class: 'text-left' },
                { label: this.$t('pump_install.operator_name'), class: 'text-left' },
                { label: this.$t('pump_install.pump_address'), class: 'text-left' },
                { label: this.$t('pump_install.payment_status'), class: 'text-left' },
                { label: this.$t('pump_install.scheme_license_no'), class: 'text-left' },
                { label: this.$t('pump_install.application_status'), class: 'text-left' },
                { label: this.$t('pump_install.view_application'), class: 'text-left' },
                { label: this.$t('pump_install.notes'), class: 'text-left' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'org_name_bn' },
                    { key: 'farmer_id' },
                    { key: 'name_bn' },
                    { key: 'division_name_bn' },
                    { key: 'payment_status' },
                    { key: 'scheme_license_no' },
                    { key: 'status_bn' },
                    { key: 'view_application' },
                    { key: 'notes' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'org_name' },
                    { key: 'farmer_id' },
                    { key: 'name' },
                    { key: 'division_name' },
                    { key: 'payment_status' },
                    { key: 'scheme_license_no' },
                    { key: 'status_en' },
                    { key: 'view_application' },
                    { key: 'notes' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        },
        'search.org_id': function (newVal, oldVal) {
            if (this.search.newVal !== 0) {
                this.orgErrorMsg = false
            } else {
                this.orgErrorMsg = true
            }
        },
        'search.far_division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.far_district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'search.far_upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
        }
    },
    mounted () {
        core.index()
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.org_id
            })
        } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id,
                far_division_id: this.authUser.office_detail.division_id,
                far_district_id: this.authUser.office_detail.district_id,
                far_upazilla_id: this.authUser.office_detail.upazilla_id
            })
        }
    },
    methods: {
        searchData () {
            this.reloadListHeading++
            if (this.search.division_id) {
                const objectData = this.$store.state.commonObj.divisionList.find(item => item.value === this.search.division_id)
                this.division_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
            }
            if (this.search.district_id) {
                const objectData = this.$store.state.commonObj.districtList.find(item => item.value === this.search.district_id)
                this.district_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
            }
            if (this.search.upazilla_id) {
                const objectData = this.$store.state.commonObj.upazilaList.find(item => item.value === this.search.upazilla_id)
                this.upazila_text = this.$i18n.locale === 'bn' ? objectData.text_bn : objectData.text_en
            }

            if (this.search.org_id !== 0) {
                this.showData = true
                this.loadData()
            } else {
                this.orgErrorMsg = true
            }
        },
        setRowId (item) {
            this.testId = item.id
        },
        loadData () {
            this.$store.commit('mutateCommonProperties', {
                loading: true
            })
            RestApi.getData(irriSchemeServiceBaseUrl, pumpInstallPumpOperatorReport, this.search).then(response => {
                if (response.success) {
                    this.dataList(response)
                }
                this.$store.commit('mutateCommonProperties', {
                    loading: false
                })
            }, error => {
                this.$store.commit('mutateCommonProperties', {
                    loading: false
                })
                if (error) {
                    //
                }
            })
        },
        dataList (data) {
            if (data.data.length !== 0) {
                this.showData = true
            }
            const organizationList = this.$store.state.commonObj.organizationProfileList
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const unionList = this.$store.state.commonObj.unionList
            const pumpOperatorData = data.data.map(item => {
                const statusObject = this.applicationStatusList.find(appStatus => appStatus.value === item.status)
                const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
                const divisionObject = divisionList.find(division => division.value === item.far_division_id)
                const districtObject = districtList.find(district => district.value === item.far_district_id)
                const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
                const unionObject = unionList.find(union => union.value === item.far_union_id)
                const statusData = {
                    status_en: statusObject.text,
                    status_bn: statusObject.text_bn
                }
                const orgData = {
                    org_name: orgObject.text,
                    org_name_bn: orgObject.text_bn
                }
                const divisionData = {
                    division_name: divisionObject.text,
                    division_name_bn: divisionObject.text_bn
                }
                const districtData = {
                    district_name: districtObject.text,
                    district_name_bn: districtObject.text_bn
                }
                const upazillaData = {
                    upazilla_name: upazilaObject.text,
                    upazilla_name_bn: upazilaObject.text_bn
                }
                const unionData = {
                    union_name: unionObject.text,
                    union_name_bn: unionObject.text_bn
                }
            return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, statusData)
        })
        this.pumpReportList = pumpOperatorData
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Pump Operator Report' : 'পাম্প অপারেটর রিপোর্ট'
            ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, this.pumpReportList, this,
                this.division_text,
                this.district_text,
                this.upazila_text
            )
        }
    }
}
</script>
